/**
 * [oauth module]
 */
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = function () {
  let _store = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER));
  _store = !_store ? {} : _store;

  // 登入者身分，[user]:合作廠商/公司員工，[member]:車主
  _store.maProfile = _store.maProfile != null ? _store.maProfile : '';
  // 登入者基本資料
  _store.maUser = _store.maUser != null ? _store.maUser : undefined;
  // OAuth Tokens
  _store.accessToken = _store.accessToken != null ? _store.accessToken : '';
  _store.refreshToken = _store.refreshToken != null ? _store.refreshToken : '';
  // 登入處理狀態([0]:預設，[1]: 登入成功，[2]: 登入處理中，[-1]:登入失敗)
  _store.status = 0;
  // 訊息說明
  _store.msg = '';
  // 轉址url
  _store.redirect_success = '/home';
  _store.redirect_fail = '/logout';

  return _store;
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
