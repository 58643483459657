import router from '@/router';

/**
 * 錯誤msg消息提示的函數
 */
export const tip = (msg) => {
  alert('error: ' + msg);
};

/**
 * 跳轉回首頁
 * 攜帶當前的頁面路由，登入完成後跳轉回原本頁面
 */
export const toLogin = () => {
  router.replace({
    name: 'Login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  });
};

/**
 * 跳轉到 403 error page
 * 攜帶當前的頁面路由，點擊連結後跳轉回原本頁面
 */
export const to403Page = () => {
  router.replace({
    name: '403'
  });
};

/**
 * [decodeApiResponse] 解析api回傳內容
 * @param mix response  api回傳資料
 */
export const decodeApiResponse = function (response = null) {
  // console.log(response);
  // 成功
  if (response && response.status === 200) {
    // 伺服器回傳檔案型式 or 請求伺服器回傳 blob型式
    if ((!!response.headers && response.headers.isfile != null && !!response.headers.isfile) || (response.config.responseType != null && response.config.responseType === 'blob')) {
      return { status: true, data: response.data };
    } else if (response.data && response.data.status === 100) {
      // 其他
      return {
        status: true,
        data: response.data.data != null ? response.data.data : true
      };
    }
  }

  // 失敗
  let fail = null;
  if (
    response == null ||
    response.status !== 200 ||
    response.data == null ||
    !response.data.status
  ) {
    fail = { status: 201, msg: 'api失敗' };
  } else if (response.data.status !== 100) {
    fail = { status: response.data.status, msg: response.data.msg, data: response.data.data };
  }
  // origin request data
  fail.req = {
    url: response.config.url,
    method: response.config.method,
    headers: response.headers,
    data: response.config.data
  };
  // console.log('api fail: ' + JSON.stringify(fail));
  return { status: false, msg: fail.msg, data: fail };
};
