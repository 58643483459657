/**
 * [store module] 紀錄 store 底層使用到的 actions
 */
import { GB_RESET_STATE } from './mutations_type';

export default {
  /**
   * [reset] 初始化 state
   */
  resetState ({ commit }) {
    commit(GB_RESET_STATE);
  }
};
