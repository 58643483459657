/**
 * [store module] 紀錄 store 底層使用到的 state
 */
// oauth module
const moduleOauth = {
  // 初始值
  maProfile: '',
  maUser: undefined,
  accessToken: '',
  refreshToken: '',
  loginStatus: false,
  loginError: null,
  loginSuccess: false,
  successRedirect: ''
};

// export (by module分類存放)
export default {
  modulOauth: moduleOauth
};
