import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import LoginVerify from '@util/common/loginVerify.js';

Vue.use(VueRouter);

const routes = [
  {
    // root
    path: '/',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home'),
    meta: {
      requiresOAuth: true,
      menuShow: false,
      icon: 'mdi-home-city'
    }
  },
  // {
  //   // root
  //   path: '/',
  //   redirect: '/login',
  //   // component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
  //   meta: {
  //     // requiresOAuth: true, // 是否驗證OAuth
  //     // menuShow: false
  //   }
  // },
  {
    // login
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
    meta: {
      requestOAuth: false,
      menuShow: false,
      menuLabel: 'PFWAY-會員平台 - 登入'
    }
  },
  {
    // home
    path: '/home', // start with '/' will be treated as a root path
    name: 'Home', // name of this route, when you already set default route ('/') in children, then disable this option (find '/ad' for reference)
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    // 如果import 寫在最上方，而非routes 定義(定義寫法入下列)，generate chunk all in app.[hash].js for this route
    // component: Home,
    meta: {
      requestOAuth: true, // 是否驗證OAuth
      menuShow: true, // 是否在導航欄中顯示
      menuLabel: 'CRM 中心', // 導航欄中顯示的名稱
      isCollapse: false, // 導航欄中是否預設子選項開合狀態
      icon: 'mdi-home' // 'mdi-house-outline' // 'mdi-home-city' // 導航欄選項的icon圖示，house-door-fill
      // mainbox: 'card'
    }
    /**
     * children for nested paths and path as start with / will be treated as a root path
     * make sure vue.config.js set 'publicPath' is '/' not './' or ''
     * /
    children: [
      {...},
      {...}
    ]
    */
  },
  {
    // dashboard
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    meta: {
      requestOAuth: true,
      menuShow: true,
      menuLabel: '儀表板',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-file-chart'
      // mainbox: 'card'
    }
  },
  {
    // installation
    path: '/inst',
    name: 'Inst',
    component: () => import(/* webpackChunkName: "inst" */ '../views/Installation'),
    meta: {
      requestOAuth: true,
      menuShow: true,
      menuLabel: '需求單管理',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-wrench-outline'
      // mainbox: 'card'
    },
    children: [
      {
        path: '/inst/list',
        name: 'InstList',
        component: () => import(/* webpackChunkName: "inst_list" */ '../views/Installation/list'),
        meta: {
          requestOAuth: true,
          menuShow: true,
          menuLabel: '需求單列表',
          icon: ''
        }
      },
      {
        path: '/inst/edit',
        name: 'InstEdit',
        component: () => import(/* webpackChunkName: "inst_edit" */ '../views/Installation/edit'),
        meta: {
          requestOAuth: true,
          menuShow: true,
          menuLabel: '編輯需求單',
          icon: ''
        }
      }
    ]
  },
  // {
  //   // inbound
  //   path: '/inbound',
  //   name: 'Inbound',
  //   component: () => import(/* webpackChunkName: "inbound" */ '../views/Inbound'),
  //   meta: {
  //     requestOAuth: true,
  //     menuShow: true,
  //     menuLabel: '進貨管理',
  //     // lv: 0, // 階層
  //     isCollapse: false, // 子選單是否已展開
  //     icon: 'mdi-blur-linear'
  //     // mainbox: 'card'
  //   }
  // },
  // {
  //   // order
  //   path: '/order',
  //   name: 'Order',
  //   component: () => import(/* webpackChunkName: "order" */ '../views/Order'),
  //   meta: {
  //     requestOAuth: true,
  //     menuShow: true,
  //     menuLabel: '出貨管理',
  //     // lv: 0, // 階層
  //     isCollapse: false, // 子選單是否已展開
  //     icon: 'mdi-cart-outline'
  //     // mainbox: 'card'
  //   }
  // },
  // {
  //   // prodt
  //   path: '/prodt',
  //   name: 'Prodt',
  //   component: () => import(/* webpackChunkName: "prodt" */ '../views/Prodt'),
  //   meta: {
  //     requestOAuth: true,
  //     menuShow: true,
  //     menuLabel: '商品管理',
  //     // lv: 0, // 階層
  //     isCollapse: false, // 子選單是否已展開
  //     icon: 'mdi-storefront-outline'
  //     // mainbox: 'card'
  //   }
  // },
  // {
  //   // stock
  //   path: '/stock',
  //   name: 'Stock',
  //   component: () => import(/* webpackChunkName: "stock" */ '../views/Stock'),
  //   meta: {
  //     requestOAuth: true,
  //     menuShow: true,
  //     menuLabel: '庫存管理',
  //     // lv: 0, // 階層
  //     isCollapse: false, // 子選單是否已展開
  //     icon: 'mdi-server'
  //     // mainbox: 'card'
  //   }
  // },
  // {
  //   // depot
  //   path: '/depot',
  //   name: 'Depot',
  //   component: () => import(/* webpackChunkName: "depot" */ '../views/Depot'),
  //   meta: {
  //     requestOAuth: true,
  //     menuShow: true,
  //     menuLabel: '車廠管理',
  //     // lv: 0, // 階層
  //     isCollapse: false, // 子選單是否已展開
  //     icon: 'mdi-car-cog'
  //     // mainbox: 'card'
  //   }
  // },
  {
    // vendor
    path: '/vendor',
    name: 'Vendor',
    component: () => import(/* webpackChunkName: "vendor" */ '../views/Vendor'),
    meta: {
      requestOAuth: true,
      menuShow: true,
      menuLabel: '廠商管理',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-office-building-outline'
      // mainbox: 'card'
    },
    children: [
      {
        path: '/vendor/list',
        name: 'VendorList',
        component: () => import(/* webpackChunkName: "vendor_list" */ '../views/Vendor/list'),
        meta: {
          requestOAuth: true,
          menuShow: true,
          menuLabel: '廠商列表',
          icon: ''
        }
      }
      // {
      //   path: '/inst/edit',
      //   name: 'InstEdit',
      //   component: () => import(/* webpackChunkName: "inst_edit" */ '../views/Installation/edit'),
      //   meta: {
      //     requestOAuth: true,
      //     menuShow: true,
      //     menuLabel: '編輯需求單',
      //     icon: ''
      //   }
      // }
    ]
  },
  // {
  //   // member
  //   path: '/member/:cid',
  //   name: 'Member',
  //   component: () => import(/* webpackChunkName: "member" */ '../views/Member'),
  //   meta: {
  //     requestOAuth: true,
  //     menuShow: true,
  //     menuLabel: '會員管理',
  //     // lv: 0, // 階層
  //     isCollapse: false, // 子選單是否已展開
  //     icon: 'mdi-account-group-outline',
  //     // mainbox: 'card'
  //     redirect: '/member/list'
  //   },
  //   children: [
  //     {
  //       path: '/member/list',
  //       name: 'MemberList',
  //       component: () => import(/* webpackChunkName: "memberlist" */ '../views/Member/List'),
  //       meta: {
  //         requestOAuth: true,
  //         menuShow: true,
  //         menuLabel: '會員列表',
  //         icon: ''
  //       }
  //     },
  //     {
  //       path: '/member/Add',
  //       name: 'MemberAdd',
  //       component: () => import(/* webpackChunkName: "memberadd" */ '../views/Member/Add'),
  //       meta: {
  //         requestOAuth: true,
  //         menuShow: true,
  //         menuLabel: '新增會員',
  //         icon: ''
  //       }
  //     }
  //     // { //
  //     //   path: '/member/:cid',
  //     //   name: 'Member',
  //     //   component: () => import(/* webpackChunkName: "member" */ '../views/Member'),
  //     //   meta: {
  //     //     requestOAuth: true,
  //     //     menuShow: false,
  //     //     menuLabel: '個人資料',
  //     //     icon: 'mdi-account-details-outline'
  //     //   }
  //     // }
  //   ]
  // },
  // {
  //   // system
  //   path: '/sys',
  //   name: 'System',
  //   component: () => import(/* webpackChunkName: "system" */ '../views/System'),
  //   meta: {
  //     requestOAuth: true,
  //     menuShow: true,
  //     menuLabel: '系統設定',
  //     // lv: 0, // 階層
  //     isCollapse: false, // 子選單是否已展開
  //     icon: 'mdi-cogs'
  //     // mainbox: 'card'
  //   },
  //   children: [
  //     {
  //       path: '/sys/',
  //       name: 'ClassSetting',
  //       component: () => import(/* webpackChunkName: "memberlist" */ '../views/System/cs'),
  //       meta: {
  //         requestOAuth: true,
  //         menuShow: true,
  //         menuLabel: '通用代碼',
  //         icon: ''
  //       }
  //     }
  //   ]
  // },
  {
    // logout
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout'),
    meta: {
      requestOAuth: false,
      menuShow: false,
      menuLabel: '登出',
      icon: 'mdi-logout'
    }
  },
  {
    // 當 url path 不符合 router 表的時候，預設轉址到 login，順序一定要最後面
    path: '/*',
    redirect: '/login'
  }
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 路由攔截token驗證;
router.beforeEach((to, from, next) => {
  // console.log('[router-beforeEach] passed ...... to / from');
  // console.log(to);
  // console.log(from);
  // console.log(routes);
  // console.log(localStorage);
  const authRequired = 'requestOAuth' in to.meta ? to.meta.requestOAuth : false;
  const doLogin = to.name === 'Login'; // || to.name === 'LoginCRM';
  // const doLogout = to.name === 'Logout';
  const _login = LoginVerify.check_login();
  // const isLogin = !!localStorage.getItem('accessToken') && 'maUser' in localStorage;
  // const maUser = isLogin ? JSON.parse(localStorage.getItem('maUser')) : null;
  // const maProfile = isLogin ? localStorage.getItem('maProfile') : null; // member or user
  // // 是否逾期
  // let isExpired = false;
  // const currTS = Math.floor(Date.now() / 1000);
  // if ('maUser' in localStorage && maUser.authentication !== null && maUser.authentication.access_token !== null && maUser.authentication.access_token.timestamp !== null) {
  //   isExpired = currTS >= maUser.authentication.access_token.timestamp ? true : isExpired;
  // }

  // console.log(`*authRequired: ${authRequired}，profile: ${localStorage.getItem('maProfile')}，doLogin: ${doLogin}，isLogin: ${isLogin}，doLogout: ${doLogout}，isExpired: ${isExpired}`);
  // console.log(maUser);

  // 需先登入才能使用的路由
  if (authRequired) {
    if (!_login.isLogin) { // 尚未登入
      next('/login');
    }
    if (_login.isExpired) { // token已逾期
      next('/logout');
    }
  }

  // 目的頁是登入頁
  if (doLogin) {
    if (_login.isLogin) { // 已是登入狀態 (依身分自動轉向登入後首頁)
      next('/home');
    }
  }
  // console.log(to);
  next();
});

export default router;
