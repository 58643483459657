// // babek for IE11, if you are using 'useBuiltIns':'entry', you should add these following two line.
// // These should be in front of all vue-related modules.
// import 'core-js';
// import 'regenerator-runtime';
// // css polyfill for IE11
// import cssVars from 'css-vars-ponyfill';
// cssVars();

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
// import i18n from './i18n';
import VueI18n from 'vue-i18n';
import './directives';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';

new Vue({
  router,
  store,
  vuetify,
  // i18n,
  VueI18n,
  render: h => h(App)
}).$mount('#app');
