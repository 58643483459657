/**
 * OAuth 相關 api
 */
import req from './config/https.js';

/**
 * 定義接口
 * 1. apiOAuthPostTokens 取得 OAuth Tokens
 * 2. apiOAuthPostRefreshTokens 以 Refresh Tokens 取得新的 Access Tokens
 * 3. apiOAuthGetProfile 以access token 取得登入用戶資料
 *
 */
// 1.
export const apiOAuthPostTokens = (params) =>
  req('post', '/api/oauth/oauth_token', params);
// 2.
export const apiOAuthPostRefreshTokens = (params) =>
  req('post', '/api/oauth/refresh_token', params);
// 3.
export const apiOAuthGetProfile = () => req('get', '/api/profile');
