/**
 * [store module] 依 store 中(含modules)，所有使用到的 mutations，定義出個別的 mutation key
 */
// 底層
export const GB_RESET_STATE = 'resetState';

// oauth module
export const OAUTH_SET_PROCESS = 'setLoginProcess';
export const OAUTH_SET_TOKENS = 'setLoginTokens';
export const OAUTH_SET_LOGGER = 'setLogger';
export const OAUTH_CLEAR_LOGGER = 'clearLogger';
