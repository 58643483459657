import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import state from './state';
import oauthModule from './modules/oauth';

Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// });

const store = new Vuex.Store({
  namespaced: true,
  state () {
    return { ...state };
  },
  actions,
  mutations,
  modules: {
    oauth: oauthModule
  },
  // 禁止直接修改(只能透過mutations)
  strict: true
});

export default store;
