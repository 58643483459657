import Vue from 'vue';

Vue.directive('slavescroll', {
  bind: function (el, binding, vnode) {
    el.addEventListener('scroll', function () {
      onScroll(el, binding.value);
    });
  }
  //   inserted: function (el, binding, vnode) {
  //     console.log('[directive/slavescroll] ... inserted');
  //   },
  //   update: function (el, binding, vnode) {
  //     console.log('[directive/slavescroll] ... update');
  //   },
  //   componentUpdated: function (el, binding, vnode) {
  //     console.log('[directive/slavescroll] ... componentUpdated');
  //   }
}
);

function onScroll (el, id) {
  document.getElementById(id).style.top = el.scrollTop + 'px';
};
