/**
 * loginVerify.js
 * 登入相關
 */
export default {
  /**
   * [getLogger] 取得登入者資料
   */
  getLogger () {
    const vf = this.check_login();
    let _store = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER));
    // console.log(_store);
    _store = !_store ? {} : _store;
    if (vf.isLogin && !vf.isExpired) { // 有效登入狀態
      if (_store.maProfile != null && _store.maProfile === process.env.VUE_APP_USER) { // 是否比對身分
        return _store.maUser != null && !!_store.maUser ? _store.maUser : {};
      }
    }
    return {};
  },
  /**
   * [check_login] 確認登入狀態
   * @return Object
   *   [isLogin] boolean 是否已登入
   *   [isExpired] boolean 登入狀態是否逾期
   */
  check_login () {
    // console.log('[loginVerify][check_login] ...');
    let _store = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER));
    _store = !_store ? {} : _store;
    // 是否已登入
    const isLogin = _store.accessToken != null && !!_store.accessToken && 'maUser' in _store;
    const maUser = isLogin ? (_store.maUser != null ? _store.maUser : {}) : {};

    // 是否逾期
    let isExpired = false;
    const currTS = Math.floor(Date.now() / 1000);
    if (!!maUser && maUser.authentication != null && maUser.authentication.access_token != null && maUser.authentication.access_token.timestamp != null) {
      isExpired = currTS >= maUser.authentication.access_token.timestamp ? true : isExpired;
    }
    return { isLogin: isLogin, isExpired: isExpired };
  }
};
