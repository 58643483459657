/**
 * [store module] 紀錄 store 底層使用到的 mutations
 */
import { GB_RESET_STATE } from './mutations_type';
import defaultState from './state';

export default {
  /**
   * [resetState] 初始化 state
   */
  [GB_RESET_STATE] (state) {
    Object.assign(state, defaultState);
  }
};
