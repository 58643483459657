/**
 * [oauth module] getters
 */

export default {
  /**
   * [loggerProfile] 取得tokens
   * @param {*} state
   */
  loggerAccessToken (state) {
    return state.accessToken;
  },
  /**
   * [loggerProfile] 取得已登入用戶資料
   * @param {*} state
   */
  loggerProfile (state) {
    return { maProfile: state.maProfile, maUser: state.maUser };
  }
};
