/**
 * [oauth module] mutations
 */

import {
  OAUTH_SET_PROCESS,
  OAUTH_SET_TOKENS,
  OAUTH_SET_LOGGER,
  OAUTH_CLEAR_LOGGER
} from '@/store/mutations_type';

export default {
  /**
   * [setLoginProcess] 登入執行狀態
   * @param {*} state
   * @param {*} param
   *              ['action']: 執行項目
   *              ['caller']: 身分，user / member
   *              ['email']: 帳號(email)
   *              ['password']: 密碼
   *              ['remember']: 是否儲存登入狀態
   *              ['msg']: 訊息說明
   *              ['redirect']: 登入成功，傳址到指定url
   *              ['redirect_fail]: 登入失敗，轉址到傳址到指定url
   */
  [OAUTH_SET_PROCESS] (state, param) {
    if (!param || !param.action) {
      return false;
    }

    const _this = state;
    switch (param.action) {
      case 'start': // 處理中
        _this.status = 2;
        _this.maProfile = param.caller;
        _this.redirect_success = param.redirect
          ? param.redirect
          : _this.redirect_success;
        break;
      case 'ok': // 登入成功
        _this.status = 1;
        _this.msg = '登入驗證通過!';
        break;
      case 'error': // 登入失敗
        _this.status = -1;
        _this.msg = `[${param.status}] ${param.msg}: ${param.data.msg}`;
        _this.redirect_fail = param.redirect_fail
          ? param.redirect_fail
          : _this.redirect_fail;
        if (param.data) {
          console.log('[login] ...error');
          // console.log(param.data);
        }
        break;
      default:
        _this.status = -9;
        _this.msg = '[-9] 登入處理發生例外狀況';
        break;
    }
    Object.assign(state, _this);
  },

  /**
   * [setLoginTokens] 設定 tokens
   */
  [OAUTH_SET_TOKENS] (state, context) {
    state.accessToken = context.access_token;
    state.refreshToken = context.refresh_token;
    const _store = {};
    _store.maProfile = state.maProfile;
    _store.accessToken = (context.token_type + ' ' + context.access_token);
    _store.refreshToken = context.refresh_token;
    if (typeof process.env.VUE_APP_USER !== 'undefined' && process.env.VUE_APP_USER !== '') {
      localStorage.setItem(process.env.VUE_APP_USER, JSON.stringify(_store));
    }
    // localStorage.setItem('maProfile', state.maProfile);
    // localStorage.setItem(
    //   'accessToken',
    //   context.token_type + ' ' + context.access_token
    // );
    // localStorage.setItem('refreshToken', context.refresh_token);
  },

  /**
   * [setLogger] 設定 登入用戶資訊
   */
  [OAUTH_SET_LOGGER] (state, context) {
    state.maUser = context;
    // localStorage.setItem('maUser', JSON.stringify(context));
    let _store = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER));
    _store = !_store ? {} : _store;
    _store.maUser = context;
    if (typeof process.env.VUE_APP_USER !== 'undefined' && process.env.VUE_APP_USER !== '') {
      localStorage.setItem(process.env.VUE_APP_USER, JSON.stringify(_store));
    }
  },

  /**
   * [clearLogger] 登出清除用戶資訊
   */
  [OAUTH_CLEAR_LOGGER] (state) {
    // localStorage.clear();
    localStorage.removeItem(process.env.VUE_APP_USER);
    Object.keys(state).forEach(function (key) {
      state[key] = null;
    });
  }
};
