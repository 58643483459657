/**
 * https.js
 * 封裝axios，設置攔截器，統一管理錯誤
 */
import axios from 'axios';
// import router from "@/router";
// import qs from "qs";
import { tip, toLogin, to403Page, decodeApiResponse } from './utils.js';
// import { BIconLayoutTextWindowReverse } from "bootstrap-vue";

const base64 = require('js-base64').Base64;

// 新建axios實例(instance)，設置攔截器
let instance = null;

/**
  * setInstance 初始化 axios instance
  * @param string proxy  代理伺服器名稱，['/crmartapi']/['martapi']，參考 vue.config.js的 devServer.proxy
  */
const setInstance = (proxy, param) => {
  // console.log("[apis][https] setInstance proxy: [" + proxy + "]");
  // console.log(
  //   "[" +
  //     process.env.NODE_ENV +
  //     "]baseUrl: " +
  //     process.env.VUE_APP_BASE_URL +
  //     proxy
  // );
  // instance = axios.create({
  //   baseURL: process.env.VUE_APP_BASE_URL + proxy,
  // });
  // console.log(param);
  // create instance for proxy
  let _url = '';
  const _timeout = 20000; // 20sec
  // console.log("32，baseUrl: " + _url);
  if (process.env.NODE_ENV === 'development') {
    _url = process.env.VUE_APP_BASE_URL + proxy;
  } else {
    switch (proxy) {
      case '/pfwayapi':
        _url = process.env.VUE_APP_PFWAYAPI_URL;
        break;
      default:
        console.log('get baseUrl fail with proxy: ' + proxy);
        return Promise.reject(new Error('get baseUrl fail with proxy: ' + proxy));
    }
  }
  const _axios = {
    baseURL: _url,
    timeout: _timeout
  };
  // 下載檔案，請求server回傳 blob型式
  if (param) {
    if (param.isBlob != null && param.isBlob) {
      _axios.responseType = 'blob';
    }
    if (param.isfile != null && param.isfile) {
      _axios.isfile = true;
    }
    if (param.timeout != null && param.timeout > 0) {
      _axios.timeout = param.timeout;
    }
    if (param.onUploadProgress != null) {
      _axios.onUploadProgress = param.onUploadProgress;
    }
  }
  // console.log('48，baseUrl: ' + _url);

  instance = axios.create(_axios);

  // 攔截器-request
  instance.interceptors.request.use(
    (config) => {
      // console.log(
      //   '[apis][https] instance.interceptors.request ... config ... '
      // );
      // console.log(config);
      // console.log(config.data);
      const _hasFile = (config.data != null && !!config.data.isfile);
      const _doBase64 = !(_hasFile);
      // console.log({
      //   ...{ is_base64: true, data: base64.encode(JSON.stringify(config.data)) },
      //   ...config.data
      // });
      // 入參 (encode by base64)
      if (config.data != null) {
        config.data = !_doBase64 ? config.data.data : { is_base64: true, data: base64.encode(JSON.stringify(config.data)) };
      }

      // console.log(localStorage);
      let _store = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER));
      _store = !_store ? {} : _store;
      // console.log(_store);
      const token = _store.accessToken != null ? _store.accessToken : null;
      // console.log('access_token: ' + token);
      if (token) {
        config.headers = {
          ...config.headers,
          ...{
            Accept: 'application/json',
            Authorization: token
          }
        };
      }
      // 上傳檔案(好像可以不用)
      if (_hasFile) {
        config.headers = {
          ...config.headers,
          ...{ 'Content-Type': 'multipart/form-data' }
        };
      }
      // config.data = qs.stringify(config.data);

      // console.log(config);
      return config;
    },
    (error) => {
      // console.log("[apis][https] instance.interceptors.request ... error ... ");
      // console.log(error);
      return Promise.reject(error);
    }
  );

  // 攔截器-response
  instance.interceptors.response.use(
    (response) => {
      // console.log(
      //   '[apis][https] instance.interceptors.response ... response ... '
      // );
      // console.log(response);
      return decodeApiResponse(response);
    },
    (error) => {
      console.log(error);
      const { response } = error;
      // console.log("[apis][https] instance.interceptors.response ... error ... ");
      if (response) {
        // 成功發出請求且收到resp，但有error
        const _response = { status: response.status, msg: response.error };
        if (response.data != null) {
          _response.status =
            response.data.status != null
              ? response.data.status
              : _response.status;
          _response.msg =
            response.data.message != null
              ? response.data.message
              : _response.msg;
        }
        errorHandle(_response.status, _response.msg);
        return Promise.reject(error);
      } else {
        // // 成功發出請求但沒收到resp
        // if (!BIconLayoutTextWindowReverse.navigator.onLine) {
        //   tip("[apis][https] 網路出了點問題，請小夥伴重新連線受刷新網頁");
        // } else {
        //   // 可能是跨域，或是程式問題
        //   return Promise.reject(error);
        // }
        return Promise.reject(error);
      }
    }
  );
};

/**
  * 請求失敗，統一處理
  * @param {Number} status 請求失敗的狀態碼
  */
const errorHandle = (status, msg) => {
  // console.log("[apis][https] errorHandle ...");
  switch (status) {
    case 400:
      tip(msg);
      break;

    case 401:
      tip(msg);
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;

    case 403: // 權限不足
      to403Page();
      break;

    case 404:
      tip(msg);
      break;

    default:
      console.log(
        '[apis][https] resp沒有攔截到的錯誤，status: ' +
        status +
        '，msg: ' +
        msg
      );
  }
};

/**
  * 最後封裝請求方法，並使用 export default導出函數
  * @param string method
  * @param string url    (不含 http://XXXX)
  * @param mixed data    api入參
  * @param string proxy  預設:'/crmartapi'，指定proxy，代理到不同的api url(定義在vue.config.js的 devServer.proxy)
  * */
export default function (method, url, data = null, proxy = '') {
  // console.log("[apis][https] default ...... ");
  // console.log({
  //   method: method,
  //   url: url,
  //   data: data,
  //   proxy: proxy,
  //   accessToken: !!localStorage.getItem('user') && localStorage.getItem('user').accessToken ? localStorage.getItem('user').accessToken : null;
  // });
  // set axios instance
  proxy = proxy === '' ? process.env.VUE_APP_PROXY : proxy;
  setInstance(proxy, data);

  // run method
  method = method.toLowerCase();
  let result = null;
  switch (method) {
    case 'post':
      // console.log('[https][post] ...');
      result = instance.post(url, data);
      break;
    case 'get':
      // console.log('[https][get] ...');
      result = instance.get(url, { params: data }); // params option as request with get method
      // result = instance.get(url, data);
      break;
    case 'delete':
      // console.log('[https][delete] ...');
      result = instance.delete(url, { data: data }); // data option as request with delete method
      break;
    case 'put':
      // console.log('[https][put] ...');
      result = instance.put(url, data); // like store with post method
      break;
    default:
      // console.log('[https][default]] ...');
      console.error('未知的method: ' + method);
      return false;
  }
  // console.log(result);
  return result;
}
